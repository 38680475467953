import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { Col, Container, Row } from "react-bootstrap"
import * as styles from "../styles/about.module.scss"
import TitlesSection from "../components/TitlesSection"
import { GatsbyImage } from "gatsby-plugin-image"
import SEO from "../components/Seo"

export default function about({ data }) {
  return (
    <Layout>
      <SEO title="About" />
      <Container fluid className={styles.containerAbout}>
        <Row className={styles.containerImageAboutRow}>
          <Col>
            <div className={styles.containerImageAbout}>
              <GatsbyImage
                style={{
                  width: "100%",
                  height: "100%",
                }}
                image={data.file.childImageSharp.gatsbyImageData}
                alt="banner about"
              />
            </div>
          </Col>
        </Row>

        <TitlesSection title="nosotros" />
        <Row className={styles.rowAbout}>
          <Col
            md={6}
            style={{
              backgroundColor: "white",
            }}
          >
            <p>
              TORUS es una productora audiovisual con base en Argentina y
              proyección internacional que surge a partir de la idea de ofrecer
              productos audiovisuales de autor dentro de la convergencia digital
              del mercado audiovisual y mediático.
            </p>
          </Col>
          <Col md={6} style={{ backgroundColor: "white" }}>
            <p>
              Nuestra misión es desarrollar producciones rentables de alta
              calidad con un sello propio y que refleje un origen colectivo y
              artístico. Por eso nos involucramos en cada proyecto íntegramente,
              de principio a fin. Para Torus cada proyecto es único.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query AboutBanner {
    file(relativePath: { eq: "aboutBanner.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 50
          transformOptions: { fit: COVER }
        )
      }
    }
  }
`
