import React from "react"
import * as styles from "../styles/titlesSection.module.scss"

export default function TitlesSection({ title }) {
  return (
    <div className={styles.containerTitle}>
      <h2>{title}</h2>
    </div>
  )
}
